html, body, #root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #2f2f2f;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, select {
  width: 100%;
  background: #262626;
  border: 1px solid #3a3a3a;
  border-radius: .75rem;
  font-size: 1rem;
  height: 3.5rem;
  color: white;
  outline: none !important;
  padding: .5rem 2rem .5rem .5rem;
  box-sizing: border-box;
}

select {
  flex-grow: 0;
  width: 4rem;
  padding: 0.5rem 0 0.5rem 0.5rem;
}

::-webkit-scrollbar {
  width: .5rem;
}

::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.1);
}

::-webkit-scrollbar-thumb {
  background-color: #444444;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a4a4a4;
}
div.languages {
  margin: 0;
  overflow: auto;
  padding: 1.5rem 1rem 1.5rem 1.5rem;
  box-sizing: border-box;
  min-width: 15rem;
  width: 15rem;
  max-width: 15rem;
  max-height: 100vh;
  transition: max-width .25s, min-width .25s, width .25s, padding-left .25s, padding-right .25s;
}

div.languages.collapsed {
  overflow: hidden;
  width: 0;
  min-width: 0;
  max-width: 0;
  padding-left: 0;
  padding-right: 0;
}

div.languages button {
  position: fixed;
  top: 0.25rem;
  left: 0.25rem;
  background: transparent;
  border: 1px solid #505050;
  color: #a5a5a5;
  padding: 0.2rem;
  border-radius: 0.25rem;
  cursor: pointer;
  z-index: 2;
}

div.languages ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

div.languages input {
  font-size: 1rem;
  height: 3.5rem;
}
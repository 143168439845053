.App {
  text-align: center;
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

main {
  flex-grow: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
}

main #searchbar {
  position: relative;
  display: flex;
}

main #searchbar::before {
  content: '👀';
  width: 3rem;
  color: #3a3a3a;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  background: #3a3a3a;
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
  border-left: 1px solid #262626;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  line-height: 3.25rem;
  text-align: center;
}

#results {
  margin-top: 1.5rem;
  text-align: left;
}

.disambig-see-also {
  opacity: .75;
}

hr {
  border: 0;
  border-bottom: 1px solid #414141;
  background: transparent;
}

a {
  color: #8ed0d2;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sister-project {
  display: none;
}

div#results * {
  background: transparent !important;
}